<template>
    <div class="news-page">
        <div class="bannar">
            <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else muted="muted" id="startvideo" class="video" :src="bannerSrc" type="video/mp4"  autoplay="autoplay" controls="controls" loop="-1">
                <p>你的浏览器不支持video标签.</p>
            </video>
            <div class="overlay"></div>
            <div class="bannar-info">
                <div class="bannar-title">天府国际赛道</div>
                <div class="bannar-detail">{{bannerTitle}}</div>
            </div>
        </div>
        <main>
            <div style="textAlign:center">
                <div class="title">天府国际赛道</div>
                <!-- <img :src="info.src" style="width:100%"> -->
            </div>
            <div v-html="info.content" style="textAlign:center" class="details-content"></div>
           
        </main>
        <!-- <bottom-box></bottom-box> -->
    </div>
</template>
<script>
import {getBanner,getOur} from '../../../api/homeApi'
export default {
    data() {
        return {
            bannerSrc: '',
            bannerType: 1, //1.图片 0.视频
            bannerTitle: '',
            newsList: [],
            info: {},
        }
    },
    created() {
        this.id = this.$route.params.id
        this.getBanner()
        this.getInfo()
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        getBanner() {
            getBanner({
                plate: 0,
            }).then(res => {
                if(res.code == 0) {
                    if(res.infos.length > 0) {
                        if(this._isMobile()) {
                            this.bannerType = 1
                            this.bannerSrc = JSON.parse(res.infos[0].mobileImage)[0].url
                        }else {
                            let arr = JSON.parse(res.infos[0].image)
                            if([
                                'image/png',    // .png
                                'image/jpeg',   // .jpg .jpeg
                                'image/x-ms-bmp', // .bmp
                                'image/gif',    // .gif
                                'image/tiff',   // .tif .tiff
                            ].includes(arr[0].type)) {
                                this.bannerType = 1
                            }else {
                                this.bannerType = 0
                            }
                            this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        }
                        this.bannerTitle = res.infos[0].content
                    }
                }
            })
        },
        // 获取咨询
        getInfo() {
            getOur().then(res => {
                if(res.code == 0) {
                    this.info = res.info
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
@media screen and(max-width:900px) {
    .news-page {
        .bannar {
            width: 100%;
            // height: 5.5rem;
            margin-top: 20px;
            background: #666;
            position: relative;
            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                background: rgba(0,0,0,.4);
                z-index: 9;
            }
            img {
                vertical-align: middle;
                height: 30vw;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                top: 20%;
                left: 0;
                width: 100%;
                text-align: center;
                padding: 0 20px;
                .bannar-title {
                    font-size: 36px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 24px;
                    color: #fff;
                }
            }
        }
        main {
            box-sizing: border-box;
            // width: 1200px;
            padding: .8rem 20px;
            background-color: #fff;
            margin: 0 auto;
            .title {
                font-size: 32px;
                margin-bottom: 30px;
            }
        }
    }
}
@media screen and(min-width:900px) {
    .news-page {
        .bannar {
            width: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            img {
                vertical-align: middle;
                height: 550px;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                top: 40%;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 9;
                .bannar-title {
                    font-size: 36px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 24px;
                    color: #fff;
                }
            }
        }
        main {
            box-sizing: border-box;
            width: 1200px;
            padding: .8rem 0rem;
            background-color: #fff;
            margin: 0 auto;
            .title {
                font-size: 32px;
                margin-bottom: 30px;
            }
        }
    }
}
 /deep/.details-content {
        p{
            span {
                color: #666 !important;
            }
        }
    }    
</style>